<template>
  <SidebarMenu />
  <div style="display:flex" class="container">
    <div class="form-group">
      <label>Instancia:</label>
      <select v-model="selectedDB">
        <option value="ccu_cordoba">CCU CORDOBA</option>
        <option value="ccu_lujan">CCU LUJAN</option>
        <option value="ccu_mendoza">CCU MENDOZA</option>
        <option value="ccu_munro">CCU MUNRO</option>
        <option value="ccu_rosario">CCU ROSARIO</option>
        <option value="ccu_salta">CCU SALTA</option>
        <option value="ccu_sanjuan">CCU SAN JUAN</option>
        <option value="ccu_santafe">CCU SANTA FE</option>
        <option value="ccu_sauceviejo">CCU SAUCE VIEJO</option>
        <option value="etex_starosa">ETEX STA ROSA</option>
        <option value="etex_starosaalmacen">ETEX STA ROSA ALMACEN</option>
        <option value="jauserclv2">JAUSER CLV2</option>
        <option value="melicad">MELI CAD</option>
        <option value="meliciudad">MELI CIUDAD</option>
        <option value="rilcomaruy">RILCOMAR UY</option>
        <option value="saenzbrionesciudadela">S.B.CIUDADELA</option>
        <option value="scrapservice">SCRAP SERVICE</option>
        <option value="ctigroupmx">CTI GROUP MX</option>
        <option value="etex_maipu">ETEX MAIPU</option>
        <option value="etex_maipu_almacen">ETEX MAIPU ALMACEN</option>
        <option value="malteria_pampa">MALTERIA PAMPA</option>
        <option value="malteria_tres_arroyos">MALTERIA TRES ARROYOS</option>
        <option value="maxidiesel">MAXIDIESEL</option>
        <option value="maxidiesel_ulc">MAXIDIESEL ULC</option>
        <option value="planta_sullana">PLANTA SULLANA</option>
        <option value="georgalos">GEORGALOS</option>
        <option value="planta_sur">PLANTA SUR</option>
        <option value="prodeman">PRODEMAN</option>
        <option value="raffo">RAFFO</option>
        <option value="t24">T24</option>
        <option value="tslogistics">TS LOGISTICS</option>
        <option value="andreu">ANDREU</option>
        <option value="arglog_alsina">ARGLOG ALSINA</option>
        <option value="ceramica_canuelas">CERAMICA CAÑUELAS</option>
        <option value="cmq_burzaco">CMQ BURZACO</option>
        <option value="cmq_servimax_mc">CMQ SERVIMAX MC</option>
        <option value="cocacola_ezeiza">COCA COLA EZEIZA</option>
        <option value="dds_turnos">DDS TURNOS</option>
        <option value="dds_avellaneda">DDS AVELLANEDA</option>
        <option value="dds_bahia_blanca">DDS BAHIA BLANCA</option>
        <option value="dds_cordoba2">DDS CORDOBA2</option>
        <option value="dds_corrientes">DDS CORRIENTES</option>
        <option value="dds_flexilog">DDS FLEXILOG</option>
        <option value="dds_mendoza">DDS MENDOZA</option>
        <option value="dds_rio_negro">DDS RIO NEGRO</option>
        <option value="dds_santo_tome">DDS SANTO TOME</option>
        <option value="fncuy">FNC UY</option>
        <option value="tienda_inglesa">TIENDA INGLESA</option>
        <option value="tienda_inglesa_saravia">TIENDA INGLESA SARAVIA</option>
      </select>
    </div>
    <div class="form-group">
      <label>Consulta:</label>
      <select v-model="selectedQuery">
        <option v-for="option in queryOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
      </select>
    </div>
    <div class="form-group">
      <label>Inicio:</label>
      <input type="date" v-model="startDate">
    </div>
    <div class="form-group">
      <label>Fin:</label>
      <input type="date" v-model="endDate">
    </div>
    <button @click="fetchData" :disabled="isLoading">{{ isLoading ? 'Cargando...' : 'Consultar' }}</button>
    <button @click="downloadExcel" :disabled="tableData.length === 0">Exportar (Excel)</button>
    <div class="counter">REGISTROS: {{tableData.length}}</div>
    <div class="counter1">CREADOS POR ADMIN: {{messagesData.length}}</div>
    <br><br>
  </div>
  <div class="table-container">
    <el-table :data="tableData" style="width: 100%" class="table">
      <el-table-column prop="id" label="ID" width="180"></el-table-column>
      <el-table-column prop="second_field" label="Second Field" width="180"></el-table-column>
      <el-table-column :label="'Start Time (GMT-3)'" width="180">
        <template v-slot="{row}">
          {{ moment(row.start_time).utcOffset('-03:00').format('YYYY-MM-DD HH:mm:ss') }}
        </template>
      </el-table-column>
      <el-table-column label="Instancia Web" width="180">
        <template v-slot="{row}">
          {{ row.location }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { ref } from 'vue'
import axios from 'axios'
import * as XLSX from 'xlsx'
import moment from 'moment'
import SidebarMenu from './SideBar.vue'

export default {
  name: 'RecordsTable',
  components: { SidebarMenu },
  computed: {
    queryOptions () {
      const dbValuesWithOnlyCtRegistradoOk = ['maxidiesel', 'maxidiesel_ulc']
      const options = [
        { value: 'ct_records', label: 'CONTAR REGISTROS' },
        { value: 'ct_registrado_ok', label: 'CONTAR REGISTRADO OK' },
        { value: 'get_users', label: 'GET USERS' }
      ]
      if (dbValuesWithOnlyCtRegistradoOk.includes(this.selectedDB)) {
        return [options[1], options[2]]
      } else {
        return [options[0], options[2]]
      }
    }
  },
  mounted () {
    const token = localStorage.getItem('ed_facturar_token')
    if (!token) {
      this.$router.push('/login') // Redirect the user to the login page if they are not authenticated
    }
  },
  setup () {
    const tableData = ref([])
    const messagesData = ref([])
    const selectedDB = ref('')
    const selectedQuery = ref('')
    const startDate = ref('')
    const endDate = ref('')
    const location = ref('')
    const authToken = 'Bearer ' + localStorage.getItem('ed_facturar_token')
    const isLoading = ref(false)

    const fetchData = async () => {
      isLoading.value = true
      const startTime = '00:00:00'
      const endTime = '23:59:59'
      const startDateTime = startDate.value + ' ' + startTime
      const endDateTime = endDate.value + ' ' + endTime
      let queryUrl, queryName
      if (selectedQuery.value === 'ct_records') {
        queryUrl = 'https://toolkit.easy-docking.com.ar/api/getaddrecords'
        queryName = 'records'
      } else if (selectedQuery.value === 'ct_registrado_ok') {
        queryUrl = 'https://toolkit.easy-docking.com.ar/api/getregokrecords'
        queryName = 'registrado_ok'
      } else if (selectedQuery.value === 'get_users') {
        queryUrl = 'https://toolkit.easy-docking.com.ar/api/getusers'
        queryName = 'users'
      } else {
        isLoading.value = false
        console.log(`Unknown query selected: ${selectedQuery.value}`)
        return
      }

      try {
        const response = await axios.post(queryUrl, {
          db: selectedDB.value,
          start: startDateTime,
          end: endDateTime
        }, {
          headers: {
            Authorization: authToken
          }
        })
        // console.log(response)
        if (selectedQuery.value === 'get_users') {
          const userData = response.data.users.map(row => {
            return {
              id: row.group_name,
              second_field: row.name,
              start_time: row.created,
              location: row.email
            }
          })

          isLoading.value = false
          tableData.value = userData
          return
        }

        // Parse start_time to GMT-3
        const recordsParsedData = response.data.records.map(row => {
          const startTimeGmt3 = moment.utc(row.start_time).utcOffset('-03:00').format('YYYY-MM-DD HH:mm:ss')
          return { ...row, start_time: startTimeGmt3, location: response.data.location_name }
        })
        const messagesParsedData = response.data.messages.map(row => {
          const dateTimeGmt3 = moment.utc(row.datetime).utcOffset('-03:00').format('YYYY-MM-DD HH:mm:ss')
          return { ...row, datetime: dateTimeGmt3 }
        })
        isLoading.value = false
        tableData.value = recordsParsedData
        messagesData.value = messagesParsedData
        location.value = response.data.location_name
      } catch (error) {
        isLoading.value = false
        console.log(`Error fetching ${queryName} data:`, error)
      }
    }
    const downloadExcel = () => {
      // Convert table data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(tableData.value, {
        dateNF: 'yyyy-mm-dd hh:mm:ss'
      })

      // Convert worksheet to workbook
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

      // Generate Excel file and download it
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const fileName = `records_${selectedDB.value}_${moment(startDate.value).format('YYYYMMDD')}_${moment(endDate.value).format('YYYYMMDD')}.xlsx`
      const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = fileName
      link.click()
    }

    return { tableData, isLoading, messagesData, selectedDB, selectedQuery, startDate, endDate, location, fetchData, downloadExcel, moment }
  }
}
</script>
<style scoped>
body {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  min-height: 100vh;
  font-family: 'Jost', sans-serif;
  background: white
}

.container h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.container label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.container select {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.container input[type="date"] {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
}

.container button {
  padding: 5px 10px;
  font-size: 15px;
  background-color: #4aeef9;
  color: #000000;
  border-radius: 5px;
  border: none;
  margin: 15px;
}

.container button:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.counter {
  padding: 15px;
  font-size: 15px;
  background-color: #5FB565;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin: 15px;
  text-align: center;
}
.counter1 {
  padding: 15px;
  font-size: 15px;
  background-color: #6C7274;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin: 15px;
  text-align: center;
}

.table-container {
  width: 100%;
  margin: 20px auto;
  border-color: black;
}
</style>
